import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
import InlineCode from '@components/InlineCode';
import TextLink from '@components/TextLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As React components become the building blocks of our application, styling them becomes a crucial part of our development. Scaling these styles as our codebase grows is critical – balancing modularity, performance, and developer experience.`}</p>
    <p>{`Today, there are many ways to apply styles to your React, just to name a few popular methods:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactjs.org/docs/dom-elements.html#style"
        }}>{`Inline Styles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://reactjs.org/docs/faq-styling.html#how-do-i-add-css-classes-to-components"
        }}>{`CSS Classes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/css-modules/css-modules"
        }}>{`CSS Modules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/tuchk4/awesome-css-in-js"
        }}>{`CSS-in-JS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stylable.io"
        }}>{`Stylable`}</a></li>
    </ul>
    <p>{`Let's take a closer look at a CSS-in-JS approach used by `}<a parentName="p" {...{
        "href": "https://theme-ui.com"
      }}>{`Theme UI`}</a>{` which provides amazing developer ergonomics while respecting design constraints.`}</p>
    <h2>{`A quick setup`}</h2>
    <p>{`The up-coming examples assume a project that has already been set up with Theme UI. To set up a project with Theme UI:`}</p>
    <ul>
      <li parentName="ul">{`Install the Theme UI package with `}<inlineCode parentName="li">{`npm i theme-ui`}</inlineCode></li>
      <li parentName="ul">{`Wrap your application with the `}<inlineCode parentName="li">{`ThemeProvider`}</inlineCode>{` passing in the `}<inlineCode parentName="li">{`theme`}</inlineCode>{` object as a prop`}</li>
      <li parentName="ul">{`See the `}<a parentName="li" {...{
          "href": "https://theme-ui.com/getting-started"
        }}>{`Getting Started`}</a>{` section for more details`}</li>
    </ul>
    <h2>{`The sx prop`}</h2>
    <p>{`Theme UI introduces a powerful styling feature, the `}<a parentName="p" {...{
        "href": "https://theme-ui.com/sx-prop"
      }}>{`sx prop`}</a>{`. This prop lets you style elements inline using values from your `}<a parentName="p" {...{
        "href": "https://theme-ui.com/theming"
      }}>{`theme`}</a>{`.`}</p>
    <p>{`You can add the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop to your JSX elements by simply adding the custom `}<inlineCode parentName="p">{`/** @jsx jsx */`}</inlineCode>{` pragma comment to the top of your file and importing the `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` function from `}<inlineCode parentName="p">{`theme-ui`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`/** @jsx jsx */
import { jsx } from 'theme-ui';

export default (props) => (
  <div
    {...props}
    sx={{
      // values referencing scales defined in a theme
      color: 'primary',
      bg: 'background',
      fontFamily: 'body',
      // raw CSS value
      boxShadow: '0 0 1px 3px rgba(0, 0, 0, .125)',
    }}
  />
);
`}</code></pre>
    <p>{`This is a similar method to the `}<a parentName="p" {...{
        "href": "https://emotion.sh/"
      }}>{`Emotion`}</a>{` `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop, but now we have the nifty features of Theme UI:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Theme-Aware Properties`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Responsive Values`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Functional Values`}</a></li>
    </ul>
    <h2>{`First-class component support`}</h2>
    <p>{`Instead of bringing in all of Theme UI into our project, we can leverage the smaller `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/develop/packages/css/README.md"
      }}><inlineCode parentName="a">{`@theme-ui/css`}</inlineCode></a>{` package to add `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop powers to individual components. Theme UI uses this package to add `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop support to its foundational Box component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`/**
 * Simplified modification of the Theme UI Box
 * http://bit.ly/3pzPiAr
 */

import styled from '@emotion/styled';
import { css } from '@theme-ui/css';

// Parse the style objects passed via \`sx\` to the \`css(...)\` function
const sx = (props) => css(props.sx)(props.theme);

const Box = styled.div(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  sx,
  (props) => props.css
);

export default Box;
`}</code></pre>
    <p>{`Now, Box has `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop support integrated into the component and can be used without the need for the JSX pragma.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`/** No need to import the theme-ui jsx pragma! */
import Box from './components/Box'

export const Card = ({ sx, ...props }) => (
  <Box
    {...props}
    sx={{
      border: '1px solid',
      borderColor: 'border',
      borderRadius: "grayLight"
      p: 3,
      ...sx
    }}
  />
)

export default Card;
`}</code></pre>
    <p>{`Moving the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` function to its own utility file, this helper can be used to add the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop as a first-class styling feature for any component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import styled from '@emotion/styled';
import sx from '../utils/sx';

const Button = styled.button(
  {
    appearance: 'none',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
    fontSize: 'inherit',
    px: 3,
    py: 2,
    color: 'white',
    bg: 'primary',
    border: 0,
    borderRadius: 4,
  },
  sx
);

export default Button;
`}</code></pre>
    <h2>{`Next steps`}</h2>
    <p>{`This is a simple approach for adding a powerful styling layer to your React components, allowing for flexibility while promoting development within the theme constraints.`}</p>
    <p>{`I recommend checking out the Theme UI source code on how this technique is taken one step farther in adding theme-based `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/stable/packages/components/src/Box.js#L13"
      }}>{`variants`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/stable/packages/components/src/Box.js#L12"
      }}>{`base styles`}</a>{`, and hooking in `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/stable/packages/components/src/Box.js#L4"
      }}>{`additional`}</a>{` prop conveniences from `}<a parentName="p" {...{
        "href": "https://github.com/styled-system/styled-system"
      }}>{`Styled System`}</a>{`.`}</p>
    <p>{`You can expand on this technique by using your foundational `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component as the basis for all your UI pieces. More on that in part 2, `}<a parentName="p" {...{
        "href": "/posts/the-box"
      }}>{`Building from the Box`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      